import { useContext, useEffect } from 'react';

import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { hasBMPageLoadStarted, isBMPageLoad } from '@confluence/browser-metrics';
import type { ProgressBarContainer } from '@confluence/global-progress-bar-container';

/**
 * "contentSlug" is changable due to url encode, content title contains special charactor, changed title, etc.
 * If "contentSlug" changed, URL will be redirected, but the page content won't rerender.
 * In this case, the progress bar should not show up.
 * To handle that, use "contentId" as screen's identity, instead of pathname.
 * Assuming if a URL contains "contentSlug" param, then it much contains "contentId" as well.
 * The above assumption is valid according to: next/packages/named-routes/src/index.ts
 *
 * Another circumstance that progress bar should not show up is, when blog post url changed from pattern VIEW_BLOG_DATE_LEGACY to VIEW_BLOG or conversely.
 */
function isPreviousPage(match, prevMatch) {
	if (
		match &&
		match.params &&
		match.name &&
		prevMatch &&
		prevMatch.params &&
		prevMatch.name &&
		(match.name === prevMatch.name ||
			(match.name.startsWith('VIEW_BLOG') &&
				prevMatch.name.startsWith('VIEW_BLOG') &&
				'contentSlug' in match.params &&
				'contentSlug' in prevMatch.params) ||
			(match.name === 'HOME' && prevMatch.name === 'WIKI'))
	) {
		return prevMatch.params.contentId === match.params.contentId;
	}
	return false;
}

export const useProgressBarController = (progressBar: ProgressBarContainer) => {
	const { match } = useContext(RoutesContext);

	useEffect(() => {
		if (!isPreviousPage(match, progressBar.prevMatch)) {
			// in all cases, BM page loads should start the progress bar.
			if (isBMPageLoad(match) && hasBMPageLoadStarted()) {
				progressBar.start();
			} else {
				progressBar.reset();
			}
		}

		progressBar.prevMatch = match;

		return () => {
			progressBar.reset();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match && match.pathname]);
};
