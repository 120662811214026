import { LoadableAfterPaint } from '@confluence/loadable';

export {
	SpaceNavigation,
	SpaceNavigationLoader,
	SpaceNavigationLoadingSkeleton,
} from './SpaceNavigation';

export { GlobalNavigation } from './GlobalNavigation';

export const AdminNavigationLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-AdminNavigation" */ './AdminNavigation'))
			.AdminNavigation,
});
