export const ORIGIN_ATTRIBUTES = 'onboarding-origin-state:attributes';

export const QUICKSTART_STATE_KEYS = {
	IS_PANEL_OPEN: 'quickstart-state:isPanelOpen',
	IS_DISMISSED: 'quickstart-state:isDismissed',
	OPEN_TASK_ID: 'quickstart-state:openTaskId',
	OPEN_COMPONENT_ID: 'quickstart-state:openComponentId',
	JSW_CONFLUENCE_INTRO_TASK: 'quickstart-state:jswConfIntroCompleted',
	HELP_COMPLETED: 'quickstart-state:helpCompleted',
	SPACE_MODAL_COMPLETED: 'quickstart-state:spaceModalCompleted',
	SPACE_TOUR_COMPLETED: 'quickstart-state:spaceTourCompleted',
	COLLABORATE_COMPLETED: 'quickstart-state:collaborateCompleted',
	HOME_MODAL_COMPLETED: 'quickstart-state:homeModalCompleted',
	HOME_TOUR_COMPLETED: 'quickstart-state:homeTourCompleted',
	MOBILE_COMPLETED: 'quickstart-state:mobileCompleted',
	HAS_SEEN_DISMISSED_FLAG: 'quickstart-state:hasSeenDismissedFlag',
	IS_MIGRATED_USER_V2: 'quickstart-state:isMigratedUserV2',
	JSW_XFLOW_SWITCHER_TOUR_TASK: 'quickstart-state:jswXflowSwitcherTourCompleted',
	ORIGIN_ATTRIBUTES,
	JSW_CONFLUENCE_MODAL: 'quickstart-state:jswXFlowModalCompleted',
	EDITOR_TUTORIAL_TASK_COMPLETED: 'quickstart-state:editorTutorialTaskCompleted',
	QSV2_READ_GUIDE_COMPLETED: 'quickstart-state:qsv2ReadConfluenceGuideCompleted',
	QSV2_INVITE_TEAMMATE_TASK: 'quickstart-state:qsv2InviteTeammateCompleted',
	QSV2_INTEGRATION_TASK: 'quickstart-state:qsv2IntegrationCompleted',
	QSV2_VIDEO_TOUR_COMPLETED: 'quickstart-state:qsv2SiteVideoTourCompleted',
	QSV2_SPOTLIGHT_COMPLETED: 'quickstart-state:qsv2SpotlightCompleted',
	MIGRATIONS_WELCOME_TASK: 'quickstart-state:migrationsWelcomeCompleted',
	MIGRATIONS_NEW_PAGE_TASK: 'quickstart-state:migrationsNewPageCompleted',
	MIGRATIONS_COLLABORATE_TASK: 'quickstart-state:migrationsCollaborateCompleted',
	MIGRATIONS_FIND_AND_ORGANIZE_PAGES_COMPLETED:
		'quickstart-state:migrationsFindAndOrganizePagesCompleted',
	MIGRATIONS_EDIT_AND_CUSTOMIZE_CONTENT_TASK:
		'quickstart-state:migrationsEditAndCustomizeContentCompleted',
	SIMPLIFY_ADMIN_RENAME_TASK: 'quickstart-state:simplifyAdminRenameSiteCompleted',
	NQSV2_HELP_TASK_COMPLETED: 'quickstart-state:nqsv2HelpCompleted',
	NQSV2_CREATE_TASK_COMPLETED: 'quickstart-state:nqsv2CreateCompleted',
	NQSV2_COLLABORATE_TASK_COMPLETED: 'quickstart-state:nqsv2CollaborateCompleted',
	NQSV2_CONFLUENCE_101_TASK: 'quickstart-state:nqsv2Confluence101Completed',
};
export const INVITE_TEAMMATE_TASK = 'quickstart-checklist.invite-teammate';
export const INTEGRATION_TASK = 'quickstart-checklist.integration';
export const JSW_XFLOW_SWITCHER_TOUR_TASK = 'quickstart-checklist.jsw-xflow-switcher-tour';
export const JSW_CONFLUENCE_INTRO_TASK = 'quickstart-checklist.jsw-conf-intro';
export const JSW_CONFLUENCE_MODAL = 'quickstart-checklist.jswXFlowModalCompleted';
export const SHOW_SWITCHER_SPOTLIGHT_ONLY = 'SHOW_SWITCHER_SPOTLIGHT_ONLY';
export const QSV2_INVITE_TEAMMATE_TASK = 'quickstart-checklist.invite-teammate-v2';
export const QSV2_CONFLUENCE_GUIDE_TASK = 'quickstart-checklist.confluence-guide-v2';
export const QSV2_INTEGRATION_TASK = 'quickstart-checklist.integration-v2';
export const QSV2_SITE_TOUR_TASK = 'quickstart-checklist.site-tour-v2';
export const QSV2_SPOTLIGHT = 'quickstart-checklist.close-spotlight';
export const MIGRATIONS_WELCOME_TASK = 'quickstart-checklist.migrations.welcome';
export const MIGRATIONS_NEW_PAGE_TASK = 'quickstart-checklist.migrations.new-page';
export const MIGRATIONS_COLLABORATE_TASK = 'quickstart-checklist.migrations.collaborate';
export const MIGRATIONS_FIND_AND_ORGANIZE_PAGES_TASK =
	'quickstart-checklist.migrations.find-and-organize-pages';
export const MIGRATIONS_EDIT_AND_CUSTOMIZE_CONTENT_TASK =
	'quickstart-checklist.migrations.edit-and-customize-content';
export const SIMPLIFY_ADMIN_RENAME_TASK = 'quickstart-checklist.simplify.admin.rename-site';
export const NQSV2_HELP_TASK = 'quickstart-checklist.nth-user-v2.help';
export const NQSV2_CONFLUENCE_101_TASK = 'quickstart-checklist.nth-user-v2.confluence-101';
export const NQSV2_CREATE_TASK = 'quickstart-checklist.nth-user-v2.create';
export const NQSV2_COLLABORATE_TASK = 'quickstart-checklist.nth-user-v2.collaborate';

export const TASK_ID_TO_STATE_KEY = {
	[INVITE_TEAMMATE_TASK]: 'inviteTeammateCompleted',
	[INTEGRATION_TASK]: 'integrationCompleted',
	[JSW_XFLOW_SWITCHER_TOUR_TASK]: 'jswXflowSwitcherTourCompleted',
	[JSW_CONFLUENCE_INTRO_TASK]: 'jswConfIntroCompleted',
	[JSW_CONFLUENCE_MODAL]: 'jswXFlowModalCompleted',
	[QSV2_CONFLUENCE_GUIDE_TASK]: 'qsv2ReadConfluenceGuideCompleted',
	[QSV2_INVITE_TEAMMATE_TASK]: 'qsv2InviteTeammateCompleted',
	[QSV2_INTEGRATION_TASK]: 'qsv2IntegrationCompleted',
	[QSV2_SITE_TOUR_TASK]: 'qsv2SiteVideoTourCompleted',
	[QSV2_SPOTLIGHT]: 'qsv2SpotlightCompleted',
	[MIGRATIONS_WELCOME_TASK]: 'migrationsWelcomeCompleted',
	[MIGRATIONS_NEW_PAGE_TASK]: 'migrationsNewPageCompleted',
	[MIGRATIONS_COLLABORATE_TASK]: 'migrationsCollaborateCompleted',
	[MIGRATIONS_FIND_AND_ORGANIZE_PAGES_TASK]: 'migrationsFindAndOrganizePagesCompleted',
	[MIGRATIONS_EDIT_AND_CUSTOMIZE_CONTENT_TASK]: 'migrationsEditAndCustomizeContentCompleted',
	[SIMPLIFY_ADMIN_RENAME_TASK]: 'simplifyAdminRenameSiteCompleted',
	[NQSV2_CREATE_TASK]: 'nqsv2CreateCompleted',
	[NQSV2_COLLABORATE_TASK]: 'nqsv2CollaborateCompleted',
	[NQSV2_HELP_TASK]: 'nqsv2HelpCompleted',
	[NQSV2_CONFLUENCE_101_TASK]: 'nqsv2Confluence101Completed',
};

export const IGNORE_COMPLETE_TASK_ON_TOGGLE = new Set([
	INVITE_TEAMMATE_TASK,
	JSW_CONFLUENCE_INTRO_TASK,
	QSV2_CONFLUENCE_GUIDE_TASK,
	QSV2_INVITE_TEAMMATE_TASK,
	QSV2_INTEGRATION_TASK,
	QSV2_SITE_TOUR_TASK,
	MIGRATIONS_WELCOME_TASK,
	MIGRATIONS_NEW_PAGE_TASK,
	MIGRATIONS_COLLABORATE_TASK,
	MIGRATIONS_EDIT_AND_CUSTOMIZE_CONTENT_TASK,
	MIGRATIONS_FIND_AND_ORGANIZE_PAGES_TASK,
	SIMPLIFY_ADMIN_RENAME_TASK,
	NQSV2_COLLABORATE_TASK,
	NQSV2_CONFLUENCE_101_TASK,
]);

export const REFETCH_EVENTS = {
	ONBOARDING_ATTRIBUTES_UPDATED: 'onboardingAttributesUpdated',
	CACHE_UPDATE_FAILED: 'cacheUpdateFailed',
};
