import type { FC } from 'react';
import React, { useState, useContext, useMemo } from 'react';

export type RightSidebarType = {
	width: number;
	panelId: string;
	isCollapsed: boolean;
	isMinimized: boolean;
};

type RightSidebarStateType = Array<RightSidebarType>;

type RightSidebarContextType = {
	sidebars: RightSidebarStateType;
	open: (panelId: string, width: number, isMinimized?: boolean, toTop?: boolean) => void;
	close: (panelId: string) => void;
	closeCurrent: () => void;
	closeAll: () => void;
	minimize: (panelId: string) => void;
	getCurrent: () => RightSidebarType | null;
	triggerButtonRef?: React.RefObject<HTMLButtonElement> | null;
	setTriggerButtonRef?: (ref: React.RefObject<HTMLButtonElement>) => void;
};

// export for testing
export function getContextValue(
	sidebars: RightSidebarStateType,
	setSidebars: (sidebars: RightSidebarStateType) => void,
	triggerButtonRef?: React.RefObject<HTMLButtonElement> | null,
	setTriggerButtonRef?: (ref: React.RefObject<HTMLButtonElement>) => void,
) {
	const entries: Array<[string, number]> = sidebars.map((s, i) => [s.panelId, i]);

	const index = entries.reduce((obj: Record<string, number>, [key, val]) => {
		obj[key] = val;
		return obj;
	}, {});

	return {
		sidebars,
		open: (panelId: string, width: number, isMinimized = false, toTop = true) => {
			const newSidebars = Array.from(sidebars);
			if (panelId in index) {
				newSidebars.splice(index[panelId], 1);
			}

			(toTop ? Array.prototype.unshift : Array.prototype.push).call(newSidebars, {
				panelId,
				width,
				isCollapsed: false,
				isMinimized,
			});

			setSidebars(newSidebars);
		},
		close: (panelId: string) => {
			const idx = index[panelId];
			if (idx >= 0) {
				const newSidebars = Array.from(sidebars);
				newSidebars.splice(idx, 1);
				setSidebars(newSidebars);
			}
		},
		closeCurrent: () => {
			const idx = currentSidebarIndex(sidebars);
			if (idx >= 0) {
				const newSidebars = Array.from(sidebars);
				newSidebars.splice(idx, 1);
				setSidebars(newSidebars);
			}
		},
		closeAll: () => {
			setSidebars([]);
		},
		minimize: (panelId: string) => {
			const idx = index[panelId];
			if (idx >= 0) {
				const newSidebars = Array.from(sidebars);
				newSidebars[idx].isMinimized = true;
				setSidebars(newSidebars);
			}
		},
		getCurrent: () => {
			const idx = currentSidebarIndex(sidebars);
			return idx >= 0 ? sidebars[idx] : null;
		},
		triggerButtonRef,
		setTriggerButtonRef,
	};
}

function currentSidebarIndex(sidebars: RightSidebarStateType) {
	if (sidebars.length === 1) {
		return 0;
	}
	return sidebars.findIndex((s) => !s.isCollapsed && !s.isMinimized);
}

export const RightSidebarContext = React.createContext<RightSidebarContextType>({
	sidebars: [],
	open: () => {},
	close: () => {},
	closeCurrent: () => {},
	closeAll: () => {},
	minimize: () => {},
	getCurrent: () => null,
	triggerButtonRef: null,
	setTriggerButtonRef: () => {},
});

export const useRightSidebarContext = () => useContext(RightSidebarContext);

export const RightSidebarContextProvider: FC<{
	children?: React.ReactNode;
}> = ({ children }) => {
	const [sidebars, setSidebars] = useState<RightSidebarStateType>([]);
	const [triggerButtonRef, setTriggerButtonRef] =
		useState<React.RefObject<HTMLButtonElement> | null>(null);
	const contextValue = useMemo(
		() => getContextValue(sidebars, setSidebars, triggerButtonRef, setTriggerButtonRef),
		[sidebars, triggerButtonRef],
	);
	return (
		<RightSidebarContext.Provider value={contextValue}>{children}</RightSidebarContext.Provider>
	);
};
