import type FeatureFlagClient from '@atlaskit/feature-flag-client';

export const isOnboardingExpEnabled = (
	featureFlagClient: FeatureFlagClient,
	shouldTrackExposureEvent: boolean = true,
): boolean =>
	featureFlagClient.getBooleanValue('confluence.frontend.onboarding.experience', {
		default: false,
		shouldTrackExposureEvent,
	});
